<template>
<div class="HPLogoutButtonContainer" v-if="this.$store.state.user.isloggedin">
    <button @click="logout()" >ausloggen</button>
</div>
    
</template>


<script>
export default {
    name: 'LogoutButton'
    , methods: {
        logout(){
            this.$store.commit('set_logout');
            this.$router.push('/login');
        }
    }
}
</script>

<style scoped>

</style>