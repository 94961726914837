<template>
  <router-view/>
  <nav style="display: none">
    <router-link to="/atwave">An der Welle</router-link> |
    <router-link to="/atwave-tablet">An der Welle Tablet</router-link> |
    <router-link to="/login">Login</router-link> |
    <router-link to="/login-employee">Mitarbeiter Login</router-link> | 
    <router-link to="/login-restaurant?tid=10">Tisch 10</router-link> |
    <router-link to="/login-restaurant?tid=11">Tisch 11</router-link>
  </nav>

</template>

<script>
  export default {







  }

</script>

<style>
#app {

  --close-height-custom-product: 30px;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

nav {
  padding: 24px;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
html{
  height: 100%;
  overscroll-behavior-y: none
}

body{
  margin-top: 0;
  height: 100%;
   height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  touch-action: none;
}

.blur * {
filter: blur(1px);
}


nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


button{
  background-color: #cf9e46;
  border: none;
  border-radius: 3px;
  color: white;
      box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
  height: 25px;
  min-width: 25px;
}

button:hover{
  cursor: pointer;
}
</style>
