import axios from 'axios';
import { createStore } from 'vuex'


export default createStore({
  state: {
    user: {
      id: '',
      firstname: '',
      lastname: '',
      isloggedin: false
    },
    posturl: 'https://portal.potsdamnights.de/config',
    table: {
      id: '',
      name: '',
      isreserved: false,
      reserve_start: '',
      reserve_end: '',
      num_people: 0
    },
    cart : {
      isFilled: false,
      nettotalprice : 0,
      totalprice: 0,
      lineItems: [],
      taxrate: 0

    }
  },
  getters: {
    getposturl(state){
        return state.posturl;
    }
    
  },
  mutations: {
    set_login(state, logindata){

      if(!logindata.status){
      state.user.id = logindata.id;
      state.user.firstname = logindata.firstName;
      state.user.lastname = logindata.lastName;
      state.user.isloggedin = true;
      }
    },
    set_logout(state){
      state.user.id = '',
      state.user.firstname = '',
      state.user.lastname ='',
      state.user.isloggedin = false
    },
    set_table(state, tableinfos){
      state.table.id = tableinfos.id;
      state.table.isreserved = tableinfos.isreserved;
      state.table.name = tableinfos.name;
      state.table.num_people = tableinfos.num_people;
      state.table.reserve_start = tableinfos.reserve_start;
      state.table.reserve_end = tableinfos.reserve_end;

      console.log(tableinfos);
    },
    set_cart(state, cartinformation){
      if(cartinformation.lineItems.length >= 1){
        state.cart.isFilled = true;
      }
      else{
        state.cart.isFilled =false;
      }
      state.cart.nettotalprice = cartinformation.price.netPrice;
      state.cart.totalprice = cartinformation.price.totalPrice;
      if(cartinformation.price.calculatedTaxes.length != 0){
        state.cart.taxrate = cartinformation.price.calculatedTaxes[0].taxRate
      }
      


      state.cart.lineItems = []
      cartinformation.lineItems.forEach(element => {
          let img;
          if(element.cover !== null){
             img = element.cover.url;
          }
          else{
             img = "https://hw2.dev.potsdamnights.de/media/e5/e8/dd/1668163607/surf.jpg";
          }
          if(element.type == 'product'){
          state.cart.lineItems.push({
            productNumber: element.payload.productNumber,
            id: element.id,
            label : element.label,
            quantity: element.quantity,
            unitPrice: element.price.unitPrice,
            totalPrice: element.price.totalPrice,
            img_url: img,
            stock: element.payload.stock,
            referencedId: element.referencedId,
            tax: element.price.calculatedTaxes[0].tax
          })
          }
          else if(element.type == 'customized-products'){

            if(element.children[0].cover != null){
                img = element.children[0].cover.url;
            }

            var option_object = [];

            element.children.forEach(option => {

              if(option.type == 'customized-products-option'){

                  if(option.payload.type == 'select'){
                    option_object.push({
                      name: option.label,
                      quantity: option.quantity,
                     selected_option: option.children[0].label,
                     price:  option.children[0].priceDefinition.price
                   })
                  }
                  else{
                    option_object.push({
                      name: option.label,
                      quantity: option.quantity,
                      price: option.priceDefinition.price,
                      value: option.payload.value
                    })
                  }
                  
              }

            });
            
            state.cart.lineItems.push({
              id: element.id,
              label : element.label,
              quantity: element.quantity,
              unitPrice: element.price.unitPrice,
              totalPrice: element.price.totalPrice,
              stock: element.payload.stock,
              img_url: img,
              referencedId: element.referencedId,
              tax: element.price.calculatedTaxes[0].tax,
              options: option_object
            })
          }
        
      });


    }
  },
  actions: {
    async login(context, credentials){
      try{
        
        const data = await axios.post(context.getters.getposturl+"/api-handler.php?operations=login", {
          "birthdate" : credentials.birthdate,
          "pin" : credentials.pin
        });
        console.log(data);
        if(!data.data.status){
          console.log("correct");
          context.commit('set_login', data.data);
          return true;
        }
        else{
          return false;
        }
      }
      catch (error) {
        
        console.log(error);
    }
    }

  },
  modules: {
  }
})
