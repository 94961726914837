import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store/index.js';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/atwave',
    name: 'atwave',
    component: () => import('../views/AtTheWave')
  },
  {
    path: '/atwave-tablet',
    name: 'atwave-tablet',
    component: () => import('../views/AtTheWaveTablet')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView')
  },
  {
    path: '/login-employee',
    name: 'login-employee',
    component: () => import('../views/LoginEmployee')
  },
  {
    path: '/employee-overview',
    name: 'employee-overview',
    component: () => import('../views/EmployeeOverview')
  },
  {
    path: '/login-restaurant',
    name: 'loginrestaurant',
    component: () => import('../views/LoginRestaurant')
  },
  {
    path: '/restaurantbooking',
    name: 'restaurantbooking',
    component: () => import('../views/RestaurantBookingView')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to) => {
  // canUserAccess() returns `true` or `false`
  if (!store.state.user.isloggedin && to.name !== 'login' && to.name !== 'login-employee' && to.name !== 'loginrestaurant' && to.name !== 'atwave'){
    return {name: 'login'}
  }

})

export default router
