<template>
  <TheHeader/>
  <div class="home">
    <img alt="Vue logo" src="../assets/Speedup_plot.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import TheHeader from "@/components/TheHeader";

export default {
  name: 'HomeView',
  components: {
    TheHeader,
    HelloWorld
  }
}
</script>
