<template>
  <header>
    <img class="HPLogo" src="https://havelwelle-potsdam.de/media/1f/58/fd/1623250780/Havelwelle_long-logo.png" />
    <h1>{{title}}</h1>
    <div class="HPTimeContainer">
      {{date}} {{time}} <br>
        <LogoutButton/>
    </div>
    
  </header>
  <hr>
</template>

<script>
import LogoutButton from './LogoutButton.vue'
export default {
  name: "TheHeader",
  components: { LogoutButton},
  props: {
    title: String,
  },
  methods: {
    get_time() {
      const d = new Date();
      let hour = d.getHours().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      let minuits = d.getMinutes().toLocaleString('en-US',{
        minimumIntegerDigits: 2 ,
        useGrouping: false
      });
      return hour + ":" + minuits;
    },
    get_date(){
      let today = new Date().toLocaleDateString()
      return today
    }
  },
  data(){
    return{
        time: '',
        timer: null,
        date: ''

    }
  },
  created() {
    this.time = this.get_time();
    this.date = this.get_date();
    this.timer = setInterval(()=>{
      this.time = this.get_time();
      this.date = this.get_date();
    },60000)
  }


}
</script>

<style scoped>

  header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .HPLogo{
    height: auto;
    width: 250px;

  }
  .HPTimeContainer{
    text-align: center;
    font-size: 20px;
  }
</style>