<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <h2>{{calculate()}}</h2>
    <h2><a v-bind:href="website" target="_blank">1 nicer Link</a></h2>
    <h1>{{myvalue}}</h1>
    <button v-on:click="add()">+</button>
    <button v-on:click="subtract">-</button>
    <br>

    <input type="text" v-model="name">
    <p>{{name}}</p>
    <p v-if=" name.length > 6"> Alles sicher</p>
    <br>
    <textarea placeholder="Schreib hier was rein"></textarea>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <br>
    <br>
    <input type="text" v-model="name_for_api">
    <br>
    <button v-on:click="call()">Wie alt bin ich</button>
    <p>{{info}}</p>

    <br>
    <br>
    <img  v-bind:src="image_link">
    <br>
    <button v-on:click="give_image">Gib mir Doggo</button>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods:{
    calculate: function (){
      return 1 + 2 +3 +4;
    },
    add: function (){
      return this.myvalue++;
    },
    subtract: function (){
      return this.myvalue--;
    },
    call: function (){
      axios.get("https://api.agify.io/?name="+this.name_for_api).then(response => (this.info = response.data.age))

    },
    give_image: function (){
      axios.get("https://dog.ceo/api/breeds/image/random").then(response =>(this.image_link = response.data.message))
    }
  },
  data() {
    return{
      website: "https://www.youtube.com/watch?v=J695pAM07Gs",
      myvalue: 42,
      name:"",
      name_for_api: null,
      info: null,
      image_link: null
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
